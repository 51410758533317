import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Reservation } from '../../interfaces/reservation';
import { ReservationService } from '../../services/reservation.service';
import { TitleService } from '../../services/title.service';
import { AgencyAutocompleteComponent } from '../autocomplete/agency-autocomplete/agency-autocomplete.component';
import { SupplierAutocompleteComponent } from '../autocomplete/supplier-autocomplete/supplier-autocomplete.component';
import { TouroperatorAutocompleteComponent } from '../autocomplete/touroperator-autocomplete/touroperator-autocomplete.component';
import { ZoneAutocompleteComponent } from '../autocomplete/zone-autocomplete/zone-autocomplete.component';
import { PassengerComponent } from '../passenger/passenger.component';
import { ServiceComponent } from '../service/service.component';
import { ServiceFormComponent } from '../services-form/services-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-reservation',
  standalone: true,
  templateUrl: './reservation.component.html',
  styleUrl: './reservation.component.css',
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatDatepickerModule,
    ServiceFormComponent,
    PassengerComponent,
    MatSelectModule,
    MatCheckboxModule,
    ServiceComponent,
    MatTooltipModule,
    AgencyAutocompleteComponent,
    TouroperatorAutocompleteComponent,
    SupplierAutocompleteComponent,
    ZoneAutocompleteComponent,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule
  ]
})
export class ReservationComponent implements OnInit {

  public field: string = "0";
  public reservationForm: FormGroup = {} as FormGroup;
  agency: string = "";
  blockPrevious: boolean = false;
  blockFollowing: boolean = false;

  loading: boolean = false;

  constructor(private reservationService: ReservationService, private _snackBar: MatSnackBar, private fb: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router, private _title: TitleService) {
    _title.setTitle("Reservas");

  }

  ngOnInit() {
    this.reservationForm = this.fb.group({
      uuid: [""],
      touroperator_uuid: [""],
      origin_zone_uuid: [""],
      destination_zone_uuid: [""],
      description: [""],
      reference: [""],
      customer_reference: [""],
      pax: [1],
      agp: [""],
      date: ["", [Validators.required]],
      baby_chair: [0],
      baby_booster: [0],
      maxi_cosi: [0],
      supplier_uuid: [""],
      invoice_row_uuid: [""],
      total: [""],
      commission: [""],
      charge: [0],
      invoiced: [false],
      drivers_charges: [false],
      private: [false],
      sport_equipement: [false],
      time: [""]
    });

    this.activatedRoute.paramMap.subscribe(params => {
      this.field = params.get("id")!;
      if (this.field != "0") {
        this.loading = true;
        if(!params.get("next")){
          this.getReservation()
        }else if(params.get("next") == "following"){
          this.getFollowingReservation();
        }else{
          this.getPreviousReservation();
        }

      }else {
        this._title.setTitle("Reservas");
        this.reservationForm.reset();
        this.reservationForm.patchValue({
          uuid: "",
          touroperator_uuid: "",
          origin_zone_uuid: "",
          destination_zone_uuid: "",
          date: "",
          pax: "",
          baby_chair: "",
          sport_equipement: "",
          description: "",
          customer_reference: "",
          agp: "",
          supplier: "",
          supplier_uuid: "",
          invoice_row_uuid :"",
          total: "",
          commission: "",
          charge: "",
          invoiced: "",
          time: "",
          drivers_charges: "",
        });
        this.agency = "";
      }
    });
  }

  getTouroperators(uuid: string) {
    this.reservationForm.controls['touroperator_uuid'].patchValue('');
    this.agency = uuid;
  }

  assignTtoo(uuid: string) {
    this.reservationForm.patchValue({
      touroperator_uuid: uuid
    });
  }

  assignS(uuid: string) {
    this.reservationForm.patchValue({
      supplier_uuid: uuid
    });
  }

  assignOrigin(uuid: string) {
    this.reservationForm.patchValue({
      origin_zone_uuid: uuid
    });
  }

  assignDestination(uuid: string) {
    this.reservationForm.patchValue({
      destination_zone_uuid: uuid
    });
  }

  getReservation() {
    this.reservationService.getReservationById(this.field).subscribe(data => {
      if (data.status == "ok") {
        this.setReservation(data.data);
      }
    })
  }

  getFollowingReservation() {
    this.reservationService.getFollowingReservation(this.field).subscribe({
      next: data => {
        if (data.status == "ok") {
          this.setReservation(data.data);
        }
      },
      error: err => {
        this.blockFollowing = true;
        this.openSnackBar("No quedan reservas posteriores");
        this.getReservation();
      }
    })
  }

  getPreviousReservation() {
    this.reservationService.getPreviousReservation(this.field).subscribe({
      next: data => {
        if (data.status == "ok") {
          this.setReservation(data.data);
        }
      },
      error: err => {
        this.blockPrevious = true;
        this.openSnackBar("No quedan reservas anteriores");
        this.getReservation();
      }
    })
  }

  following(){
    this.router.navigate(["reservation/" + this.field + "/following"]);
  }

  previous(){
    this.router.navigate(["reservation/" + this.field + "/previous"]);
  }

  setReservation(reservation: Reservation){
    if(reservation.touroperator != null){
      this.agency = reservation.touroperator?.agency_uuid!;
    }else{
      this.openSnackBar("La reserva no tiene asignado un touroperador o el que estaba asignado se ha borrado")
    }

    this.field = reservation.uuid!;

    this.reservationForm.patchValue({
      uuid: reservation.uuid,
      touroperator_uuid: reservation.touroperator != null ? reservation.touroperator.uuid : "",
      origin_zone_uuid: reservation.origin_zone_uuid,
      destination_zone_uuid: reservation.destination_zone_uuid,
      date: reservation.date,
      pax: reservation.pax,
      baby_chair: reservation.baby_chair,
      maxi_cosi: reservation.maxi_cosi,
      baby_booster: reservation.baby_booster,
      sport_equipement: reservation.sport_equipement,
      description: reservation.description,
      customer_reference: reservation.customer_reference,
      agp: reservation.agp,
      supplier: reservation.supplier,
      supplier_uuid: reservation.supplier_uuid,
      invoice_row_uuid : reservation.invoice_row?.uuid,
      total: reservation.invoice_row?.total,
      commission: reservation.invoice_row?.commission,
      drivers_charges: reservation.drivers_charges,
      invoiced: reservation.invoiced,
      charge: reservation.charge,
      time: new Date(reservation.date).getHours() + ":" + new Date(reservation.date).getMinutes(),
      private: reservation.private
    });
    this.loading = false;
    this._title.setTitle("Modificando reserva " + reservation.reference)
  }


  addReservation() {

    if(this.valid()){
      let newDate : Date = new Date(this.reservationForm.value.date);
      newDate.setHours(parseInt(this.reservationForm.value.time.split(":")[0]), parseInt(this.reservationForm.value.time.split(":")[1]));
      this.reservationForm.value.date = newDate;

      if(!this.reservationForm.value.supplier_uuid){
        delete this.reservationForm.value.supplier_uuid
      }

      if(!this.reservationForm.value.baby_booster){
        this.reservationForm.value.baby_booster = 0;
      }

      if(!this.reservationForm.value.baby_chair){
        this.reservationForm.value.baby_chair = 0;
      }

      if(!this.reservationForm.value.maxi_cosi){
        this.reservationForm.value.maxi_cosi = 0;
      }

      if(!this.reservationForm.value.charge){
        this.reservationForm.value.charge = 0;
      }

      if(!this.reservationForm.value.drivers_charges){
        this.reservationForm.value.drivers_charges = false;
      }

      if(!this.reservationForm.value.sport_equipement){
        this.reservationForm.value.sport_equipement = false;
      }

      if(!this.reservationForm.value.private){
        this.reservationForm.value.private = false;
      }

      if(!this.reservationForm.value.invoiced){
        this.reservationForm.value.invoiced = false;
      }

      this.reservationService.addReservation(this.reservationForm.value).subscribe(data => {
        if (data.status == "ok") {
          this.router.navigate(["reservation/"+ data.data.uuid]);
        }
      })
    }
  }

  updateReservation() {
    let newDate : Date = new Date(this.reservationForm.value.date);
    newDate.setHours(parseInt(this.reservationForm.value.time.split(":")[0]), parseInt(this.reservationForm.value.time.split(":")[1]));
    this.reservationForm.value.date = newDate;
    const reservation : Reservation = this.reservationForm.value;

    reservation.total = this.reservationForm.value.total;
    reservation.commission = !!this.reservationForm.value.commission ? this.reservationForm.value.commission : 0;


    // this.reservationService.updateInvoice(invoice_row).subscribe(data => {
    //   if (data.status == "ok") {

    //   }
    // })

    if(!reservation.supplier_uuid){
      delete reservation.supplier_uuid
    }

    if(!reservation.touroperator_uuid){
      delete reservation.touroperator_uuid
    }

    if(!reservation.origin_zone_uuid){
      delete reservation.origin_zone_uuid
    }

    if(!reservation.destination_zone_uuid){
      delete reservation.destination_zone_uuid
    }
    this.reservationService.updateReservation(this.reservationForm.value).subscribe(data => {
      if (data.status == "ok") {
        this.openSnackBar("Reserva modificada");
        this.getReservation();
      }
    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",
      duration: 5000
    });
  }

  errorMessage(inputName: string, inputShow: string) {
    let input = this.reservationForm.get(inputName) || null;

    if (input && input?.touched) {

      if (input.hasError('required') || false) {
        return 'Debe introducir un valor.';
      }
      return input.invalid ? inputShow + '.' : '';
    }
    return "";

  }

  valid(){
    if(!this.reservationForm.value.origin_zone_uuid || !this.reservationForm.value.destination_zone_uuid || !this.reservationForm.value.date || !this.reservationForm.value.touroperator_uuid){
      return false;
    }
    return true;
  }

}
