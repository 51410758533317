export const environment = {
    production: true,
    apiUrl: 'https://api.dev.transportgestion.com/api/',
    secretKey: "*&0M9ysI@hwNj0X#q#QG3c&rJyJIec2e!j01mJ*t4O$$*$KLEo&b79UPxOnvFSGwQ9BUTjdb4pz24WKAM#$TwNT@3kl1j%S$q1$8dSyZ47pL74f0x9XWA!3$8i67bD15",
    ver: "0.7.1 - DEV",
    devBadge: "DEV",
    ws: {
      forcetls: true,
      key: "y5ihoak6bk29qy39im9e",
      wssPort: 9443,
      wsPort: 8080
    }
};
