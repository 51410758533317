import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule, MatNavList } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { Observable, filter, map, shareReplay } from 'rxjs';
import { environment } from '../environments/environment';
import { LoadingModalComponent } from './components/modal/loading-modal/loading-modal.component';
import { ApiResponse } from './interfaces/ApiResponse';
import { logoutResponse } from './interfaces/login';
import { AuthStateService } from './services/authState.service';
import { IsloadingService } from './services/isloading.service';
import { LoginService } from './services/login.service';
import { SecureStorageService } from './services/secureStorage.service';
import { TitleService } from './services/title.service';
import { UpdaterService } from './services/updater.service';
import { WebsocketService } from './services/websocket.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatSlideToggleModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenav,
    MatNavList,
    MatIconModule,
    MatDivider,
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatBadgeModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent implements OnInit {
  title = 'reservascochesfrontend';
  @ViewChild('menuLateral') menuLateral: MatSidenav | undefined;
  sessionStatus: boolean = false;
  public name: string = "Reservas coches"

  private handset = false;

  constructor(private titleService: Title, private breakpointObserver: BreakpointObserver, private dialog: MatDialog, public loginService: LoginService, private token: SecureStorageService, private authState: AuthStateService, private router: Router, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, public _title: TitleService, private _updater: UpdaterService, private websocketService: WebsocketService, public isLoading: IsloadingService) {
    this.authState.userAuthState.subscribe(status => {
      this.sessionStatus = status;
    });

    this._title.getTitle().subscribe(title => {
      this.name = title;
    });

    this._updater.checkUpdates();

    this.isHandset$.subscribe(res => {
      this.handset = res;
    });
  }

  loadingDialogOpened: boolean = true;

  user() {
    return this.loginService.getUser();
  }

  ngOnInit() {
    this.loadingDialogOpened = true;
    const loadingDialog = this.dialog.open(LoadingModalComponent, {
      disableClose: true,
    });

    if (this.sessionStatus) {
      this.loginService.me().subscribe({
        next: (respo) => {
          this.loginService.setUser(respo.data);
          this.websocketService.initial();
          loadingDialog.close();
          this.loadingDialogOpened = false;
          this.snackBar.open(`Bienvenido de nuevo ${this.user().name}`, '', {
            duration: 1500,
          });
        },
        error: (err) => {
          loadingDialog.close();
          this.loadingDialogOpened = false;
        }
      });
    } else {
      loadingDialog.close();
      this.loadingDialogOpened = false;
      this.loadingDialogOpened = false;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.name = this.getTitle(this.activatedRoute);
      this.titleService.setTitle(this.name);
    });
  }

  private getTitle(route: ActivatedRoute): string {
    if (route.firstChild) {
      return this.getTitle(route.firstChild);
    } else {
      return route.snapshot.data['title'];
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  sesionIniciada() {
    return this.sessionStatus && this.loginService.isLoged();
  }

  // public user(): User {
  //   return this.authService.getUsuario();
  // }

  hideSideBar() {
    if (this.handset) {
      this.menuLateral?.toggle();
    }
  }

  isDev():string {
    return environment.devBadge;
  }

  logout() {
    this.loginService.logout().subscribe({
      next: (data: ApiResponse<logoutResponse>) => {
        if (data.data) {
          if (data.data.message == "logout") {
            this.websocketService.delete();
            this.authState.setAuthState(false);
            this.token.clear("token");
            let snackBarRef = this.snackBar.open('Se ha cerrado la sesión.', '', { duration: 5000 });
            this.router.navigate(['login'])
          }
        }
      },
      error: (error) => {
        if (error.status == 401) {
          this.authState.setAuthState(false);
          this.token.clear("token");
          let snackBarRef = this.snackBar.open('La sesión ha expirado.', '', { duration: 5000 });
          this.router.navigate(['login'])
        } else {
          //console.error(error);
        }
      }
    })
  }

}
